import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <div className={"simple-page"}>
      <div className={"title-block"}>
        <div className={"container-fluid"} style={{ color: "#bfaca4" }}>
          <div style={{ padding: "10% 0 2% 15%" }}>
            <h1 style={{ color: "#bfaca4" }}>404</h1>
          </div>
          <div style={{ padding: "2% 0 2% 15%" }}>
            <h3 style={{ color: "#bfaca4" }}>Page non trouvée</h3>
            Veuillez nous excuser, la page que vous cherchez semble introuvable.
          </div>
          <div style={{ padding: "2% 0 5% 15%" }}>
            <h3 style={{ color: "#bfaca4" }}>Page not found</h3>
            We apologize, the page you are looking for cannot be found.
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
